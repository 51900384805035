import { createStore } from 'vuex';
import apiModules from '@/api/index';
import * as types from './mutations_type';

import alert from './alert/index';
import questionnaire from './questionnaire/index';
import reserve from './reserve/index';
import growthTool from './growthTool/index';
import cosen from './cosen/index';
import client from './client/index';
import memberCentre from './memberCentre/index';
import rewardCard from './rewardCard/index';
import lottery from './lottery/index';
import epson from './epson/index';

export default createStore({
  strict: true,
  state: {
    finishMessage: '已提交表單',
    isLoading: false,
  },
  mutations: {
    [types.FINISH_MESSAGE](state, data) {
      state.finishMessage = data;
    },
    [types.IS_LOADING](state, data) {
      state.isLoading = data;
    },
  },
  actions: {
    getImage(_context, id) {
      return apiModules.getImage(id).then((result) => result.data);
    },
    getShareMessage(_context, {
      botId,
      clientId,
      intentId,
    }) {
      return apiModules.getShareMessage({
        botId,
        clientId,
        intentId,
      }).then((result) => result.data);
    },
    updateFinishMessage(context, payload) {
      context.commit(types.FINISH_MESSAGE, payload);
    },
    updateIsLoading(context, payload) {
      context.commit(types.IS_LOADING, payload);
    },
  },
  getters: {
    finishMessage: (state) => state.finishMessage,
    isLoading: (state) => state.isLoading,
  },
  modules: {
    alert,
    questionnaire,
    reserve,
    growthTool,
    cosen,
    client,
    memberCentre,
    rewardCard,
    lottery,
    epson,
  },
});
