import apiModules from '@/api/index';
import router from '../../router';

const verifyEpsonPassword = (_context, {
  clientId,
  password,
}) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.Epson.default.verifyEpsonPassword({
    botId,
    clientId,
    password,
  }).then((result) => result.data);
};

const generateImageByAIPrompt = (_context, {
  clientId,
  prompt,
}) => {
  const botId = router.currentRoute.value.query?.botId;
  return apiModules.Epson.default.generateImageByAIPrompt({
    botId,
    clientId,
    prompt,
  }).then((result) => result.data);
};

const print = (_context, {
  clientId,
  file,
  x,
  y,
  rotation,
  width,
  height,
}) => {
  const botId = router.currentRoute.value.query?.botId;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('x', x);
  formData.append('y', y);
  formData.append('rotation', rotation);
  formData.append('width', width);
  formData.append('height', height);
  return apiModules.Epson.default.print({
    botId,
    clientId,
    formData,
  }).then((result) => result.data).catch((error) => error.response.data);
};

export {
  verifyEpsonPassword,
  generateImageByAIPrompt,
  print,
};
