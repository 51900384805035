import { request } from '../init';

function verifyEpsonPassword({
  botId,
  clientId,
  password,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/epson/password',
      method: 'post',
      config: { params: { botId, clientId } },
    };
    request(params, {
      password,
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function generateImageByAIPrompt({
  botId,
  clientId,
  prompt,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/epson/prompt',
      method: 'post',
      config: { params: { botId, clientId } },
    };
    request(params, {
      prompt,
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function print({
  botId,
  clientId,
  formData,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/epson/print',
      method: 'post',
      config: {
        params: { botId, clientId },
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    };
    request(params, formData).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

export default {
  verifyEpsonPassword,
  generateImageByAIPrompt,
  print,
};
