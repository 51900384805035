import { request } from '../init';

function getClient({ botId, clientId }) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/client',
      method: 'get',
    };
    request(params, {
      params: {
        botId, clientId,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function sendAuthMessage({
  botId,
  phone,
  clientId,
  isResend,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/auth/sendMessage',
      method: 'get',
    };
    request(params, {
      params: {
        botId,
        phone,
        clientId,
        isResend,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function checkAuthMessage({
  botId,
  clientId,
  hashCode,
  otp,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/auth/checkMessage',
      method: 'post',
      config: { params: { botId, clientId } },
    };
    request(params, {
      authCode: {
        hashCode,
        otp,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function unbindAuth({
  botId,
  clientId,
}) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/auth',
      method: 'delete',
    };
    request(params, {
      params: {
        botId,
        clientId,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

function getClientDataByPhysicalGift({ botId, clientId }) {
  const p = new Promise((resolve, reject) => {
    const params = {
      url: '/liff/client/data',
      method: 'get',
    };
    request(params, {
      params: {
        botId, clientId,
      },
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      reject(error);
    });
  });
  return p;
}

export default {
  getClient,
  sendAuthMessage,
  checkAuthMessage,
  unbindAuth,
  getClientDataByPhysicalGift,
};
